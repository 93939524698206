import React, { useState } from "react";
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from "./navbarElements";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/auth/authSlice";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const NavBar = () => {
  const { userToken, userInfo } = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false); // Manage the state of mobile menu
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    dispatch(logout()); // Dispatch the logout action
    navigate("/login"); // Navigate to the login page after logout
  };

  return (
    <Nav>
      <Bars onClick={toggleMenu} />
      <NavMenu isOpen={isOpen}>
        <NavLink to="/" onClick={toggleMenu}>
          Home
        </NavLink>
        <NavLink to="/about" onClick={toggleMenu}>
          About
        </NavLink>
        <NavLink to="/services" onClick={toggleMenu}>
          Services
        </NavLink>
        <NavLink to="/contact" onClick={toggleMenu}>
          Contact
        </NavLink>
        {userToken ? (
          <NavBtn className="h-10">
            <Menu as="div" className="relative">
              <div>
                <Menu.Button className="flex items-center text-gray-800 bg-white hover:bg-gray-100 py-2 px-10 rounded">
                  {userInfo?.preferredName || "User"}
                  <ChevronDownIcon
                    className="ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="p-1">
                    <Menu.Item>
                      {({ active }) => (
                        <NavLink
                          to="/dashboard"
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } group flex rounded-md items-center w-full p-2 text-sm`}
                          onClick={toggleMenu}
                        >
                          Dashboard
                        </NavLink>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <NavLink
                          to="/profile"
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } group flex rounded-md items-center w-full p-2 text-sm`}
                          onClick={toggleMenu}
                        >
                          Profile
                        </NavLink>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            handleLogout();
                            toggleMenu();
                          }}
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } group flex rounded-md items-center w-full p-2 text-sm`}
                        >
                          Logout
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </NavBtn>
        ) : (
          <NavBtn>
            <NavBtnLink to="/login" onClick={toggleMenu}>
              Login
            </NavBtnLink>
          </NavBtn>
        )}
      </NavMenu>
    </Nav>
  );
};

export default NavBar;
