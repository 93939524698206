import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import "./index.css"; // Create and style this CSS file if needed

const AddProduct = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/dashboard");
  };
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;

    const fileName = `${uuidv4()}-${file.name}`;
    const fileType = file.type;

    try {
      // Step 1: Get the signed URL from the Lambda function
      const response = await axios.get(
        "https://zoxf7yp7ufcx7tbr23ntxhthiu0xiqac.lambda-url.us-east-1.on.aws/" +
          fileName +
          "&contentType=" +
          fileType
      );

      const { url } = response.data;

      // Step 2: Upload the file to S3 using the signed URL
      await axios.put(url, file, {
        headers: {
          "Content-Type": fileType,
        },
      });

      alert("File uploaded successfully!");
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file.");
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // Logic to handle product addition
    //console.log("Product added!");
    navigate("/dashboard"); // Redirect back to dashboard after adding
  };

  return (
    <div className="add-product-container">
      <button className="btn btn-secondary mb-3" onClick={handleBackClick}>
        Back to Dashboard
      </button>
      <h3>Add New Product</h3>
      <button
        onClick={() => navigate("/dashboard/addfoodItem")}
        className="btn btn-primary"
      >
        Add
      </button>
      <div className="form-group">
        <h1>S3 File Upload</h1>
        <input type="file" onChange={handleFileChange} />
        <button onClick={handleUpload} className="btn btn-primary">
          Upload to S3
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Product Name</label>
          <input type="text" className="form-control" required />
        </div>
        <div className="form-group">
          <label>Product Description</label>
          <textarea className="form-control" rows="3" required></textarea>
        </div>
        <div className="form-group">
          <label>Product Price</label>
          <input type="number" className="form-control" required />
        </div>
        <button type="submit" className="btn btn-primary">
          Add Product
        </button>
      </form>
    </div>
  );
};

export default AddProduct;
