// estoreSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const backendURL = "http://localhost:8080/citylibrary/api/v1/public";
export const fetchEstoreList = createAsyncThunk(
  "auth/estoreProfileList",
  async (_, { getState, rejectWithValue }) => {
    const userToken = getState().auth.userToken;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      const { data } = await axios.get(`${backendURL}/auth/estore`, config);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        alert(error.response.data.message + " from estoreSlice.js");
        return rejectWithValue(error.response.data.message);
      } else {
        alert(error.message + " from estoreSlice.js");
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateEstore = createAsyncThunk(
  "auth/estoreUpdate",
  async (estoreRequest, { getState, rejectWithValue }) => {
    const userToken = getState().auth.userToken;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      const { data } = await axios.put(
        `${backendURL}/auth/estore`,
        estoreRequest,
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        alert(error.response.data.message + " from estoreSlice.js");
        return rejectWithValue(error.response.data.message);
      } else {
        alert(error.message + " from estoreSlice.js");
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createEstore = createAsyncThunk(
  "auth/estoreCreate",
  async (estoreRequest, { getState, rejectWithValue }) => {
    const userToken = getState().auth.userToken;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      const { data } = await axios.post(
        `${backendURL}/auth/estore`,
        estoreRequest,
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        alert(error.response.data.message + " from estoreSlice.js");
        return rejectWithValue(error.response.data.message);
      } else {
        alert(error.message + " from estoreSlice.js");
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  isLoading: false,
  isSuccess: false,
  estore: {},
  estoreList: [],
  error: null,
};

const estoreSlice = createSlice({
  name: "estore",
  initialState,
  reducers: {
    resetEstore: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.estore = {};
      state.estoreList = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEstoreList.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(fetchEstoreList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.estoreList = action.payload;
      })
      .addCase(fetchEstoreList.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })
      .addCase(updateEstore.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(updateEstore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.estore = action.payload;
        //state.estoreList = [...state.estoreList, ...action.payload];
      })
      .addCase(updateEstore.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })
      .addCase(createEstore.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(createEstore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.estore = action.payload;
      })
      .addCase(createEstore.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload;
      });
  },
});

// Export actions and reducer
export const { resetEstore } = estoreSlice.actions;
export default estoreSlice.reducer;
