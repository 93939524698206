/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Footer() {
  return (
    <div className="flex justify-center w-full my-12">
      <p className="text-center text-gray-500 text-xs">
        &copy;{new Date().getFullYear()} Horelo, LLC All rights reserved.
      </p>
      <p className="text-center text-gray-500 text-xs">
        {(localStorage.getItem("userTokens") + "1 23456").substring(1, 20)}{" "}
      </p>
    </div>
  );
}
