import React from "react";
import "./index.css";
import { Link } from "react-router-dom";

const productData = [
  {
    title: "Web Design",
    level: "Advance",
    date: "2nd Jan 2023",
    status: "Completed",
  },
  {
    title: "React",
    level: "Advance",
    date: "9th April 2023",
    status: "On Going",
  },
  {
    title: "MongoDB",
    level: "Advance",
    date: "5th June 2023",
    status: "Interested",
  },
  {
    title: "Angular",
    level: "Beginner",
    date: "7th July 2023",
    status: "Interested",
  },
  {
    title: "CSS",
    level: "Intermediate",
    date: "2nd Feb 2023",
    status: "Completed",
  },
];

const ProductList = () => {
  const textColor = productData.map((each) => {
    if (each.status === "Completed") {
      return "green";
    } else if (each.status === "On Going") {
      return "orange";
    } else {
      return "blue";
    }
  });

  return (
    <div className="shadow mx-1 px-3 bg-light rounded-3 ">
      <div className="d-flex align-items-center justify-content-between p-3 mt-3">
        <h4 className="mb-0">Products List</h4>
        <div className="ml-auto">
          <button className="btn mr-2 btn-primary">See All</button>
          <Link to="addproduct" className="btn btn-primary">
            Add
          </Link>
        </div>
      </div>
      <div className=" ">
        <table className="table ">
          <thead>
            <tr>
              <th>Course Name</th>
              <th>Level</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {productData.map((course, index) => (
              <tr key={index}>
                <td>{course.title}</td>
                <td>{course.level}</td>
                <td>{course.date}</td>
                <td style={{ color: textColor[index] }}>{course.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductList;
