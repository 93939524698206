import React, { useState } from "react";
import "./index.css"; // Import the CSS file

const GarnishToppings = () => {
  // Initialize state with an empty list of garnishes or toppings
  const [garnishToppings, setGarnishToppings] = useState([]);
  const [newGarnish, setNewGarnish] = useState("");

  // Handle adding a new garnish or topping
  const addGarnish = () => {
    if (newGarnish.trim()) {
      setGarnishToppings([
        ...garnishToppings,
        { name: newGarnish.trim(), checked: false },
      ]);
      setNewGarnish(""); // Clear the input field
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedGarnishes = garnishToppings.map((garnish, i) =>
      i === index ? { ...garnish, checked: !garnish.checked } : garnish
    );
    setGarnishToppings(updatedGarnishes);
  };

  return (
    <div className="garnish-toppings-container">
      <h3 className="garnish-toppings-title">Garnish / Toppings</h3>
      <ul className="garnish-toppings-list">
        {garnishToppings.map((garnish, index) => (
          <li className="garnish-toppings-list-item" key={index}>
            <input
              type="checkbox"
              checked={garnish.checked}
              onChange={() => handleCheckboxChange(index)}
            />
            {garnish.name}
          </li>
        ))}
      </ul>
      <div>
        <input
          type="text"
          className="garnish-toppings-input"
          value={newGarnish}
          onChange={(e) => setNewGarnish(e.target.value)}
          placeholder="Add new garnish or topping"
        />
        <button className="garnish-toppings-button" onClick={addGarnish}>
          Add
        </button>
      </div>
    </div>
  );
};

export default GarnishToppings;
