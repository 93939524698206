import React, { useState } from "react";
import "./index.css"; // Import the CSS file

const SpiceLevels = () => {
  // Initialize state with the default list
  const [spiceLevels, setSpiceLevels] = useState([
    { name: "None", checked: false },
    { name: "Mild", checked: false },
    { name: "Medium", checked: false },
    { name: "Spicy", checked: false },
    { name: "Spicy AF", checked: false },
  ]);
  const [newSpiceLevel, setNewSpiceLevel] = useState("");

  // Handle adding a new spice level
  const addSpiceLevel = () => {
    if (newSpiceLevel.trim()) {
      setSpiceLevels([
        ...spiceLevels,
        { name: newSpiceLevel.trim(), checked: false },
      ]);
      setNewSpiceLevel(""); // Clear the input field
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedSpiceLevels = spiceLevels.map((level, i) =>
      i === index ? { ...level, checked: !level.checked } : level
    );
    setSpiceLevels(updatedSpiceLevels);
  };

  return (
    <div className="spice-levels-container">
      <h3 className="spice-levels-title">Spice Levels</h3>
      <ul className="spice-levels-list">
        {spiceLevels.map((level, index) => (
          <li className="spice-levels-list-item" key={index}>
            <input
              type="checkbox"
              checked={level.checked}
              onChange={() => handleCheckboxChange(index)}
            />
            {level.name}
          </li>
        ))}
      </ul>
      <div>
        <input
          type="text"
          className="spice-levels-input"
          value={newSpiceLevel}
          onChange={(e) => setNewSpiceLevel(e.target.value)}
          placeholder="Add new spice level"
        />
        <button className="spice-levels-button" onClick={addSpiceLevel}>
          Add
        </button>
      </div>
    </div>
  );
};

export default SpiceLevels;
