import React, { useState } from "react";
import "./index.css"; // Import the CSS file

const AvailabilityDays = () => {
  // Initialize state with the default list of days
  const [availabilityDays, setAvailabilityDays] = useState([
    { name: "all", checked: false },
    { name: "Mon", checked: false },
    { name: "Tue", checked: false },
    { name: "Wed", checked: false },
    { name: "Thu", checked: false },
    { name: "Fri", checked: false },
    { name: "Sat", checked: false },
    { name: "Sun", checked: false },
  ]);

  // Handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedDays = availabilityDays.map((day, i) => {
      // Check/uncheck all days if "all" checkbox is toggled
      if (index === 0) {
        return { ...day, checked: !availabilityDays[0].checked };
      }
      // Otherwise, toggle the checked state of the clicked checkbox
      return i === index ? { ...day, checked: !day.checked } : day;
    });

    // If a specific day is toggled and "all" is checked, uncheck "all"
    if (index !== 0) {
      const allChecked = updatedDays.slice(1).every((day) => day.checked);
      updatedDays[0].checked = allChecked;
    }

    setAvailabilityDays(updatedDays);
  };

  return (
    <div className="availability-days-container">
      <h3 className="availability-days-title">Availability Days</h3>
      <ul className="availability-days-list">
        {availabilityDays.map((day, index) => (
          <li className="availability-days-list-item" key={index}>
            <input
              type="checkbox"
              checked={day.checked}
              onChange={() => handleCheckboxChange(index)}
            />
            {day.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AvailabilityDays;
