import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages";
import Login from "./pages/Login";
import SignUp from "./pages/Signup";
import Settings from "./pages/Settings";
import Footer from "./components/Footer";
import UserProfile from "./pages/user/UserProfile";
import ProfileScreen from "./pages/user/ProfileScreen";
import ProfileEditScreen from "./pages/user/ProfileEditScreen";
import Profile from "./pages/Profile";
import Dashboard from "./components/Dashboard";
import AddProduct from "./components/Dashboard/AddProduct";
import AddFoodItem from "./components/Dashboard/AddFoodItem";
import NavBar from "./components/NavBar/index";

function App() {
  return (
    <Router>
      <>
        <NavBar />
        <div className="flex justify-center items-center">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/profiles" element={<Profile />} />
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/profile-edit" element={<ProfileEditScreen />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/addproduct" element={<AddProduct />} />
            <Route path="/dashboard/addfoodItem" element={<AddFoodItem />} />
          </Routes>
        </div>
      </>

      <Footer />
    </Router>
  );
}

export default App;
