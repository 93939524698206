import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css"; // Create and style this CSS file if needed
import OutOfStockCheckbox from "../OutOfStock";
import Availability from "../Availability";
import AvailabilityDays from "../AvailabilityDays";
import SpiceLevels from "../SpiceLevel";
import GarnishToppings from "../GarnishToppings";
import LiquidAddons from "../LiquidAddOns";
import Ingredients from "../Ingredients";

const AddFoodItem = () => {
  const navigate = useNavigate();

  // State for form inputs
  const [name, setName] = useState("");
  const [cuisineType, setCuisineType] = useState("");
  const [type, setType] = useState("Entree");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [isPublic, setIsPublic] = useState(true);

  const [garnishes, setGarnishes] = useState([]);
  const [liquidAddons, setLiquidAddons] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [spiceLevels, setSpiceLevels] = useState([]); // New state for spice levels

  // Submit data to server
  const submitData = async () => {
    // Combine garnishes, liquidAddons, ingredients, and spiceLevels into one array
    const combinedIngredients = [
      ...garnishes.map((garnish) => ({
        name: garnish.name,
        type: "garnish",
        checked: garnish.checked,
      })),
      ...liquidAddons.map((addon) => ({
        name: addon.name,
        type: "liquidAddOn",
        checked: addon.checked,
      })),
      ...ingredients.map((ingredient) => ({
        name: ingredient.name,
        type: "ingredient",
        checked: ingredient.checked,
      })),
      ...spiceLevels.map((spice) => ({
        name: spice.name,
        type: "spiceLevel",
        checked: spice.checked,
      })),
    ];

    // Combine all data into a single object
    const data = {
      name,
      cuisine_type: cuisineType,
      type,
      description,
      price,
      isOutOfStock,
      isPublic,
      ingredients: combinedIngredients,
    };

    try {
      const response = await fetch("/api/saveData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Data submitted successfully:", result);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    submitData();
  };

  const handleBackClick = () => {
    navigate("/dashboard");
  };

  return (
    <div className="add-food-item-container">
      <button className="btn btn-secondary mb-3" onClick={handleBackClick}>
        Back to Dashboard
      </button>
      <h3>Add New Food Item</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Product Name</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>
            Cuisine Type: <label className="italic-text">Optional</label>
          </label>
          <input
            type="text"
            className="form-control"
            value={cuisineType}
            onChange={(e) => setCuisineType(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Type:</label>
          <select
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="form-control"
          >
            <option value="Entree">Entree</option>
            <option value="Appetizer">Appetizer</option>
            <option value="Dessert">Dessert</option>
          </select>
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea
            className="form-control"
            rows="3"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label>Price</label>
          <input
            type="number"
            className="form-control"
            value={price}
            onChange={(e) => setPrice(parseFloat(e.target.value))}
            required
          />
        </div>

        <GarnishToppings garnishes={garnishes} setGarnishes={setGarnishes} />
        <LiquidAddons
          liquidAddons={liquidAddons}
          setLiquidAddons={setLiquidAddons}
        />
        <Ingredients
          ingredients={ingredients}
          setIngredients={setIngredients}
        />
        <SpiceLevels
          spiceLevels={spiceLevels}
          setSpiceLevels={setSpiceLevels}
        />

        <div className="d-flex">
          <div className="form-group me-2">
            <Availability isPublic={isPublic} setIsPublic={setIsPublic} />
          </div>
          <div className="form-group">
            <OutOfStockCheckbox
              isOutOfStock={isOutOfStock}
              setIsOutOfStock={setIsOutOfStock}
            />
          </div>
        </div>

        <AvailabilityDays />

        <button type="submit" className="btn btn-primary">
          Create
        </button>
      </form>
    </div>
  );
};

export default AddFoodItem;
