import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import authReducer from "./auth/authSlice";
import userReducer from "./auth/jwtSlice";
import { authApi } from "../auth/services/authService";
import counterReducer from "./features/counterSlice";
import estoreReducer from "./auth/estoreSlice";

// Define persist config for the auth slice
const persistConfig = {
  key: "root",
  storage,
};

// Create a persisted reducer for auth
const persistedAuthReducer = persistReducer(persistConfig, authReducer);

const rootReducer = combineReducers({
  estore: estoreReducer,
  user: userReducer,
  counter: counterReducer,
  auth: persistedAuthReducer,
  [authApi.reducerPath]: authApi.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(authApi.middleware),
});

// Create the persistor
const persistor = persistStore(store);

export { store, persistor };
