import React from "react";

const Company = ({ company }) => {
  const { logo, name } = company;
  //alert("company" + JSON.stringify(company));
  return (
    <div className="flex flex-row justify-evenly items-center">
      <img src={logo} alt="logo" />
      <p>name : {name}</p>
    </div>
  );
};

export default Company;
