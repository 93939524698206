import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const backendURL = "http://localhost:8080/citylibrary/api/v1/public";

// Async Thunk for fetching user details
export const getUserDetails = createAsyncThunk(
  "auth/getUserDetails",
  async (_, { getState, rejectWithValue }) => {
    const userToken = getState().auth.userToken;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      const response = await axios.get(`${backendURL}/auth/user`, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async Thunk for fetching specific user info by ID
export const getUserInfo = createAsyncThunk(
  "auth/getUserInfo",
  async (id, { getState, rejectWithValue }) => {
    const userToken = getState().auth.userToken;
    const userId = id || getState().auth.userInfo?.userId;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      const { data } = await axios.get(
        `${backendURL}/auth/user/${userId}/info`,
        config
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Async Thunk for updating user info
export const updateUserInfo = createAsyncThunk(
  "auth/updateUserInfo",
  async (userInfoRequest, { getState, rejectWithValue }) => {
    const userToken = getState().auth.userToken;
    const userId = getState().auth.userInfo?.userId;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      const { data } = await axios.put(
        `${backendURL}/auth/user/${userId}/info`,
        userInfoRequest,
        config
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Update the API call to handle both user profile data and an optional file
export const updateUserProfile = createAsyncThunk(
  "auth/updateUserProfile",
  async ({ userProfileRequest, file }, { getState, rejectWithValue }) => {
    const userToken = getState().auth.userToken;

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          // "Content-Type": "multipart/form-data", // Required for sending form data
        },
      };

      // Create a FormData object to send both user profile data and the file
      const formData = new FormData();
      formData.append(
        "userRequest",
        new Blob([JSON.stringify(userProfileRequest)], {
          type: "application/json",
        })
      );

      if (file) {
        formData.append("file", file); // Add the file only if it exists
      }

      // Send the multipart form data to the backend
      const { data } = await axios.post(
        `${backendURL}/auth/user`,
        formData,
        config
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Initialize userToken from local storage
const userToken = localStorage.getItem("userTokens") || null;

const initialState = {
  loading: false,
  userInfo: null,
  userToken,
  jwtToken: null,
  error: null,
  success: false,
};

// User slice definition
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("userToken");
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.jwtToken = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.userInfo = action.payload;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getUserInfo.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.userInfo = action.payload;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

// Export actions and reducer
export const { logout } = userSlice.actions;
export default userSlice.reducer;
