import React, { useState } from "react";
import FileUpload from "../components/utils/FileUpload";
import { useSelector } from "react-redux";
const imageUrl =
  "https://horeloimgs.s3.amazonaws.com/public-files/1726089321753_Screenshot__65_.png";
const Home = () => {
  const [error, setError] = useState(null);

  const { userToken } = useSelector((state) => state.auth);

  const handleUploadSuccess = (data) => {
    console.log("Upload successful:", data);
    // Handle the response data or update the state
  };

  const handleUploadError = (error) => {
    setError("Error uploading file. Please try again.");
    console.error("Upload error:", error);
    // Handle the error
  };

  return (
    <div>
      <div>
        <h1>Image Preview</h1>
        <img
          src={imageUrl}
          alt="Preview"
          style={{ width: "300px", height: "auto" }}
        />
      </div>
      <div>
        <h2>Upload Image here</h2>

        <FileUpload
          uploadUrl="http://localhost:8080/citylibrary/api/v1/public/auth/upload"
          authToken={userToken}
          onUploadSuccess={handleUploadSuccess}
          onUploadError={handleUploadError}
        />
      </div>

      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default Home;
