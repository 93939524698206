import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { updateUserProfile, getUserInfo } from "../../redux/auth/userSlice";
const imageUrl = "https://horeloimgs.s3.amazonaws.com/public-files/";
const ProfileEditScreen = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const userId = useSelector((state) => state.auth.userInfo?.userId);
  const { userInfo, loading, success, error } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      dispatch(getUserInfo(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (success) {
      navigate("/profile");
    }
  }, [success, navigate]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const onSubmit = (data) => {
    const userProfileRequest = { userProfileRequest: data };

    // Dispatch the action with the user profile data and the optional file
    dispatch(updateUserProfile({ userProfileRequest, file: selectedFile }))
      .unwrap()
      .then((data) => {
        navigate("/profile");
        alert("Profile updated successfully");
      })
      .catch((error) => {
        alert("Error updating profile: " + error);
      });
  };
  /* const onSubmit2 = (data) => {
    const userProfileRequest = { userProfileRequest: data };
    const formData = new FormData();

    // Append form data
    formData.append("userProfileRequest", JSON.stringify(userProfileRequest));
    if (selectedFile) {
      formData.append("file", selectedFile);
    }

    dispatch(updateUserProfile(formData))
      .unwrap()
      .then(() => {
        navigate("/profile");
        alert("Profile updated successfully");
      })
      .catch((error) => {
        alert("Error updating profile: " + error);
      });
  };
 */
  return (
    <div className="w-full max-w-xs mx-auto mt-10">
      <h3 className="text-center text-xl text-gray-900 font-medium leading-8 mb-4">
        Update Profile
      </h3>

      <form
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}

        <div>
          <p className="photo-wrapper p-2">
            <img
              src={imageUrl + userInfo.userProfile?.avatar}
              alt="Avatar"
              className="w-32 h-32 rounded-full mx-auto"
            />
          </p>
          <h1>Upload Your Image</h1>
          <input type="file" onChange={handleFileChange} accept="image/*" />
          {imagePreview && (
            <div className="mt-4">
              <img
                src={imagePreview}
                alt="Preview"
                className="w-32 h-32 object-cover rounded"
              />
            </div>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="firstName"
          >
            First Name
          </label>
          <input
            id="firstName"
            type="text"
            name="firstName"
            defaultValue={userInfo?.userProfile?.firstName || ""}
            {...register("firstName", { required: "First Name is required" })}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              errors.firstName ? "border-red-500" : ""
            }`}
          />
          {errors.firstName && (
            <p className="text-red-500 text-xs italic">
              {errors.firstName.message}
            </p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="lastName"
          >
            Last Name
          </label>
          <input
            id="lastName"
            type="text"
            name="lastName"
            defaultValue={userInfo?.userProfile?.lastName || ""}
            {...register("lastName", { required: "Last Name is required" })}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              errors.lastName ? "border-red-500" : ""
            }`}
          />
          {errors.lastName && (
            <p className="text-red-500 text-xs italic">
              {errors.lastName.message}
            </p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="phoneNumber"
          >
            Phone Number
          </label>
          <input
            id="phoneNumber"
            type="text"
            name="phoneNumber"
            defaultValue={userInfo?.userProfile?.phoneNumber || ""}
            {...register("phoneNumber", {
              pattern: { value: /^\d+$/, message: "Phone number is invalid" },
            })}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              errors.phoneNumber ? "border-red-500" : ""
            }`}
          />
          {errors.phoneNumber && (
            <p className="text-red-500 text-xs italic">
              {errors.phoneNumber.message}
            </p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="title"
          >
            Title
          </label>
          <input
            id="title"
            type="text"
            name="title"
            defaultValue={userInfo?.userProfile?.title || ""}
            {...register("title")}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="description"
          >
            Description
          </label>
          <input
            id="description"
            type="text"
            name="description"
            defaultValue={userInfo?.userProfile?.description || ""}
            {...register("description")}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="avatar"
          >
            Profile Picture URL
          </label>
          <input
            id="avatar"
            type="text"
            name="avatar"
            defaultValue={userInfo?.avatar || ""}
            {...register("avatar")}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="flex justify-between mt-6">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            disabled={loading}
          >
            {loading ? <Spinner /> : "Update"}
          </button>
          <button
            type="button"
            onClick={() => reset(userInfo)}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Reset
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProfileEditScreen;
