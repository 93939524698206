import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEstoreList } from "../../redux/auth/estoreSlice";
import Spinner from "../../components/Spinner";
import Company from "./Company";

const CompanyList = () => {
  const dispatch = useDispatch();
  const { estoreList, isLoading, error } = useSelector((state) => state.estore);

  useEffect(() => {
    dispatch(fetchEstoreList());
    //alert("fetching" + JSON.stringify(estoreList));
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex flex-col justify-evenly items-center">
      {estoreList?.map((company) => (
        <Company key={company.estoreId} company={company} />
      ))}
    </div>
  );
};

export default CompanyList;
