import React, { useState } from "react";
import "./index.css"; // Import the unified CSS file

const Ingredients = () => {
  const ingredientsArray = [
    "Tomato",
    "Lettuce",
    "Cheese",
    "Onion",
    "Garlic",
    "Pepper",
    "Salt",
    "Chicken",
    "Beef",
    "Pork",
  ];

  const [searchInput, setSearchInput] = useState("");
  const [filteredIngredients, setFilteredIngredients] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);

    if (value.length > 0) {
      const filtered = ingredientsArray.filter((ingredient) =>
        ingredient.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredIngredients(filtered);
    } else {
      setFilteredIngredients([]);
    }
  };

  const handleAddIngredient = (ingredient) => {
    if (!selectedIngredients.includes(ingredient)) {
      setSelectedIngredients([...selectedIngredients, ingredient]);
    }
    setSearchInput("");
    setFilteredIngredients([]);
  };

  const handleRemoveIngredient = (ingredient) => {
    setSelectedIngredients(
      selectedIngredients.filter((item) => item !== ingredient)
    );
  };

  return (
    <div className="form-group">
      <label>Ingredients</label>
      <div className="d-flex">
        <input
          type="text"
          className="form-control me-2"
          value={searchInput}
          onChange={handleSearchChange}
          placeholder="Search for ingredients"
        />
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleAddIngredient(searchInput)}
        >
          Add
        </button>
      </div>
      <ul className="list-group">
        {filteredIngredients.map((ingredient, index) => (
          <li
            key={index}
            className="list-group-item"
            onClick={() => handleAddIngredient(ingredient)}
          >
            {ingredient}
          </li>
        ))}
      </ul>
      <div className="selected-ingredients mt-2">
        {selectedIngredients.map((ingredient, index) => (
          <label
            key={index}
            className="badge badge-primary mr-2"
            onClick={() => handleRemoveIngredient(ingredient)}
          >
            {ingredient} &times;
          </label>
        ))}
      </div>
    </div>
  );
};

export default Ingredients;
