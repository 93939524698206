import React, { useState } from "react";
import "./index.css"; // Import the unified CSS file

const LiquidAddons = () => {
  const liquidAddonsArray = [
    "Olive Oil",
    "Vinegar",
    "Soy Sauce",
    "Hot Sauce",
    "Honey",
    "Mustard",
    "Lemon Juice",
    "Balsamic Vinegar",
    "Worcestershire Sauce",
    "Sriracha",
  ];

  const [searchInput, setSearchInput] = useState("");
  const [filteredLiquidAddons, setFilteredLiquidAddons] = useState([]);
  const [selectedLiquidAddons, setSelectedLiquidAddons] = useState([]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);

    if (value.length > 0) {
      const filtered = liquidAddonsArray.filter((addon) =>
        addon.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredLiquidAddons(filtered);
    } else {
      setFilteredLiquidAddons([]);
    }
  };

  const handleAddLiquidAddon = (addon) => {
    if (!selectedLiquidAddons.includes(addon)) {
      setSelectedLiquidAddons([...selectedLiquidAddons, addon]);
    }
    setSearchInput("");
    setFilteredLiquidAddons([]);
  };

  const handleRemoveLiquidAddon = (addon) => {
    setSelectedLiquidAddons(
      selectedLiquidAddons.filter((item) => item !== addon)
    );
  };

  return (
    <div className="form-group">
      <label>Liquid AddOn(s)</label>
      <div className="d-flex">
        <input
          type="text"
          className="form-control me-2"
          value={searchInput}
          onChange={handleSearchChange}
          placeholder="Search for Liquid Add ons"
        />
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleAddLiquidAddon(searchInput)}
        >
          Add
        </button>
      </div>
      <ul className="list-group">
        {filteredLiquidAddons.map((addon, index) => (
          <li
            key={index}
            className="list-group-item"
            onClick={() => handleAddLiquidAddon(addon)}
          >
            {addon}
          </li>
        ))}
      </ul>
      <div className="selected-liquid-addons mt-2">
        {selectedLiquidAddons.map((addon, index) => (
          <label
            key={index}
            className="badge badge-primary mr-2"
            onClick={() => handleRemoveLiquidAddon(addon)}
          >
            {addon} &times;
          </label>
        ))}
      </div>
    </div>
  );
};

export default LiquidAddons;
